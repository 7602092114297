import React from "react";
import { SEO, MarkdownContent, Accordion } from "@bluefin/components";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class FaqsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-faqs custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"dluebjbnsxoohrht"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"olmwkodemslbbntq"}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "74f3d21c-39e0-45e3-a11f-da22c341ff1b",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[0]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "f414ccdf-16ae-4854-bb33-f2ff3c2ad761",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "bf834bd9-aaca-4ab7-8fc0-52df1164e880",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "e1b86a99-342d-44c0-a196-7a467c0aab36",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "644ecf32-cfe1-4d55-880b-b54ad12b138f",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "f5edf3d1-448a-4d58-832a-aaa3fdb1779e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c27f71a2-070b-47c7-a23b-1ab2dee5f1da",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "ac8abb57-ed94-43ad-8d55-d2262160a69a",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c9ffacfb-0ad3-4ec0-b0c2-9b1db8cfaa4e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "e6ba4cda-cbdd-4eca-99e8-ac6f07c5ef7c",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "11a9633b-b2cd-4213-b28f-2b8f3cca0632",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "d7dd40ec-6b6d-4cb3-98c1-c46dad862531",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "f8598049-0251-4f95-9fc6-dde56c96096e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "530ce70d-4269-4851-abf6-2338c7a9d600",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "6c432e1d-412b-4fb0-892c-b3d2ff358747",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27585 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
